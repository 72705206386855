<template>
  <v-app>
    <dashboard-core-app-bar v-model="expandOnHover" />
    <v-main>
      <v-container tag="section">
        <v-container tag="section">
          <v-row class="justify-center">
            <v-col cols="12" sm="10">
              <page-top-title>
                <template v-slot:toptitle-icon>mdi-book-open</template>
                <template v-slot:toptitle-text>{{ $t("mi_cuenta") }}
                </template>
              </page-top-title>
            </v-col>
          </v-row>
          <v-row justify="center">
            <base-material-card icon="mdi-account-outline">
              <template v-slot:after-heading>
                <div class="font-weight-light card-title text-h2 mt-2">
                  {{ $t("actualizar_perfil") }}
                </div>
              </template>

              <validation-observer v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(update)">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="email"
                  >
                    <v-text-field
                      v-model="currentUser.email"
                      color="secondary"
                      :label="`${$t('email')}...`"
                      append-icon="mdi-email"
                      :error-messages="errors"
                      outlined
                      rounded="pill"
                    />
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="password"
                  >
                    <v-text-field
                      v-model="userData.password"
                      color="secondary"
                      :label="`${$t('contrasena')}...`"
                      append-icon="mdi-lock-outline"
                      :error-messages="errors"
                      outlined
                      rounded="pill"
                    />
                  </validation-provider>

                  <v-card-actions class="pa-0 mt-8">
                    <v-spacer />
                    <v-dialog v-model="dialog" width="500">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="red"
                          text
                          v-bind="attrs"
                          v-on="on"
                          style="padding: 0 !important;"
                        >
                          {{ $t("eliminar_cuenta") }}
                          <v-icon right dark class="mb-1">mdi-delete</v-icon>
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title class="headline text-left">
                          <h4 class="text-h4">
                            {{$t('confirma_que_quieres_eliminar_la_cuenta')}}
                          </h4>
                        </v-card-title>

                        <v-card-text>
                          {{$t('una_vez_eliminada_no_podras_volver_a_consultar_tus_datos')}}
                        </v-card-text>

                        <v-card-actions>
                          <v-row justify="center">
                            <v-btn color="red" text @click="dialog = false">
                              {{ $t("eliminar_cuenta") }}
                            </v-btn>
                            <v-btn
                              color="primary"
                              rounded="pill"
                              @click="deleteAccount"
                            >
                              {{ $t("cancelar") }}
                            </v-btn>
                          </v-row>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-btn
                      rounded
                      color="primary"
                      type="submit"
                      class="accent--text mr-0"
                    >
                      {{ $t("Actualizar perfil") }}
                    </v-btn>
                  </v-card-actions>
                </form>
              </validation-observer>
            </base-material-card>
          </v-row>
        </v-container>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from "@/services/axios.js";
import PageTopTitle from "@/components/mylogy/PageTopTitle.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      userData: {
        password: ""
      }
    };
  },
  components: {
    PageTopTitle,
    DashboardCoreAppBar: () => import("./components/core/AppBar")
  },
  computed: {
    ...mapGetters("auth", ["currentUser", "netlifyUserLoggedIn"])
  },
  methods: {
    ...mapActions("auth", ["updateUserAccount", "attemptLogout"]),
    deleteAccount() {
      let self = this;
      return axios
        .post(`/.netlify/functions/delete-user?id=${this.currentUser.id}`, {})
        .then(function() {
          self
            .attemptLogout()
            .then(() => {
              self.$router.push({ name: "Login" });
            })
            .catch(error => {
              self.$router.push({ name: "Login" });
            });
        });
    },
    update(userData) {
      let self = this
      const data = userData;
      data.email = this.currentUser.email;
      this.updateUserAccount(data)
        .then(() => {
          self.$t('tu_contrasena_ha_sido_actualizada')
        })
        .catch(error => {
           self.$t('ha_ocurrido_un_error')
          console.error("Failed to update user account: %o", error);
        });
    }
  }
};
</script>
