var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('dashboard-core-app-bar',{model:{value:(_vm.expandOnHover),callback:function ($$v) {_vm.expandOnHover=$$v},expression:"expandOnHover"}}),_c('v-main',[_c('v-container',{attrs:{"tag":"section"}},[_c('v-container',{attrs:{"tag":"section"}},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('page-top-title',{scopedSlots:_vm._u([{key:"toptitle-icon",fn:function(){return [_vm._v("mdi-book-open")]},proxy:true},{key:"toptitle-text",fn:function(){return [_vm._v(_vm._s(_vm.$t("mi_cuenta"))+" ")]},proxy:true}])})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('base-material-card',{attrs:{"icon":"mdi-account-outline"},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"font-weight-light card-title text-h2 mt-2"},[_vm._v(" "+_vm._s(_vm.$t("actualizar_perfil"))+" ")])]},proxy:true}])},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.update)}}},[_c('validation-provider',{attrs:{"rules":"required","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"secondary","label":((_vm.$t('email')) + "..."),"append-icon":"mdi-email","error-messages":errors,"outlined":"","rounded":"pill"},model:{value:(_vm.currentUser.email),callback:function ($$v) {_vm.$set(_vm.currentUser, "email", $$v)},expression:"currentUser.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"secondary","label":((_vm.$t('contrasena')) + "..."),"append-icon":"mdi-lock-outline","error-messages":errors,"outlined":"","rounded":"pill"},model:{value:(_vm.userData.password),callback:function ($$v) {_vm.$set(_vm.userData, "password", $$v)},expression:"userData.password"}})]}}],null,true)}),_c('v-card-actions',{staticClass:"pa-0 mt-8"},[_c('v-spacer'),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"padding":"0 !important"},attrs:{"color":"red","text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("eliminar_cuenta"))+" "),_c('v-icon',{staticClass:"mb-1",attrs:{"right":"","dark":""}},[_vm._v("mdi-delete")])],1)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline text-left"},[_c('h4',{staticClass:"text-h4"},[_vm._v(" "+_vm._s(_vm.$t('confirma_que_quieres_eliminar_la_cuenta'))+" ")])]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('una_vez_eliminada_no_podras_volver_a_consultar_tus_datos'))+" ")]),_c('v-card-actions',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("eliminar_cuenta"))+" ")]),_c('v-btn',{attrs:{"color":"primary","rounded":"pill"},on:{"click":_vm.deleteAccount}},[_vm._v(" "+_vm._s(_vm.$t("cancelar"))+" ")])],1)],1)],1)],1),_c('v-btn',{staticClass:"accent--text mr-0",attrs:{"rounded":"","color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("Actualizar perfil"))+" ")])],1)],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }